.linkButton {
    align-items: center;
    background-color: transparent;
    border: none;
    border-radius: 5px;
    color: #3c6e95;
    cursor: pointer;
    display: flex;
    font-size: 13px;
    gap: 10px;
    justify-content: center;
    padding: 2px 20px;
    transition: background-color .3s ease;
    font-weight: 600;
    justify-content: center;
    text-align: center;
    width: 100%;
    text-decoration: underline;
}

.linkButton:disabled {
    cursor: not-allowed;
    text-decoration: none;
}

.disabled {
    pointer-events: none;
}
.link_lundiniom, .link_magicbrick {color:#fff ;}