#simple-popover.popover_wrap .MuiPaper-root {
  width: 108px !important;
  right: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

#simple-popover.popover_wrap .MuiPaper-root p {
  font-size: 12px !important;
}

.lundiniom-calendar .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.sc-gsFSjX.eVVWqv.css-1ps6pg7-MuiPaper-root {
  width: 100% !important;
  margin-left: unset !important;
  margin-right: unset !important;
}

.lundiniom-calendar h6.MuiTypography-root div:first-child {
  text-align: left;
}

.lundiniom-calendar h6.MuiTypography-root div:last-child {
  text-align: right;
}

.lundiniom-calendar .sc-dcJtft.lcoNEU {
  text-align: left;
}