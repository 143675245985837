.container {
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    background-color: #fff;
    margin-bottom: 10px;
}

.icon {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
}

.title {
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 8px 0;
    color: #000;
}

.description {
    font-size: 13px;
    color: #666;
    margin: 0 0 16px 0;
}

.button {
    background-color: #f0f0f0;
    color: #5a189a;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #e0e0e0;
}

.chip {
    background: transparent !important;
    border: 1px solid rgb(189, 189, 189) !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #464749 !important;
}

.container.container_propertyhubltd, .container.container_lundiniom, .container.container_magicbrick {
    border: 1px solid #000;
    background: #ffc800;
}

.container.container_propertyhubltd .chip, .container.container_lundiniom .chip, .container.container_magicbrick .chip {
    background: #373737 !important;
    border: 1px solid rgb(255 255 255) !important;
    color: #fff !important;
}

.container.container_propertyhubltd .description, .container.container_lundiniom .description, .container.container_magicbrick .description {
    color: #292828;
}