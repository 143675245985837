@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

:focus-visible {
  outline: none !important;
}

.full_chatbot {
  position: fixed;
  bottom: 0px;
  right: 10px;
}

.chatbot_outer {
  display: flex;
  align-items: center;
  gap: 10px;
  background: #FFF8E2;
  padding: 10px 15px;
  border-radius: 20px 20px 0px 0px;
  justify-content: center;
}

.chatbot_outer span {
  font-weight: 600;
  color: #242424;
  font-family: 'Inter';
}



/* width */
.full_chatbot ::-webkit-scrollbar {
  width: 8px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

/* Track */
.full_chatbot ::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}

/* Handle */
.full_chatbot ::-webkit-scrollbar-thumb {
  background: #c0c0c0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

/* Handle on hover */
.full_chatbot ::-webkit-scrollbar-thumb:hover {
  background: #b8b7b7;
}



.full_chatbot .iconset .crossico {
  font-size: 32px;
  background: #B82142;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  z-index: 15;
  position: relative;
  cursor: pointer;
}

.full_chatbot .iconset .showicon {
  font-size: 32px;
  background: #B82142;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  z-index: 15;
  position: relative;
  cursor: pointer;
}

.full_chatbot .all_chatpart_design {
  position: fixed;
  bottom: 0px;
  right: 10px;
  background: #FFF8E2;
  width: 100%;
  max-width: 350px;
  box-shadow: rgba(0, 18, 46, 0.16) 0px 8px 36px 0px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}


/* user icon */
.icon_wrapper {
  background: #FED838;
  width: 44px;
  height: 44px;
  border-radius: 10px;
  border: 3px solid #FED838;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.icon_wrapper img {
  width: 22px;
}

.online_span {
  width: 10px;
  height: 10px;
  background: #75E15D;
  border-radius: 50%;
  position: absolute;
  bottom: -3px;
  right: -4px;
  z-index: 1;
}

.full_chatbot .all_chatpart_design .all_chatpart_header {
  background: transparent;
  font-size: 19px;
  font-weight: 500;
  color: #242424;
  font-family: "Inter", sans-serif;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  gap: 20px;
  padding: 15px 15px 15px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #96BFEE;
  font-weight: 600;
}

.full_chatbot .all_chatpart_design .all_chatpart_header .with_avtar {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 16px;
}

.full_chatbot .all_chatpart_design .all_chatpart_header .donw_arrow {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #464749;
}

.donw_arrow img {
  width: 35px;
}

.close_modal {
  position: absolute;
  top: -5px;
  right: -5px;
}

.full_chatbot .all_chatpart_design .all_chatpart_body {
  padding: 0 15px;
  height: calc(75vh - 260px);
  max-height: 420px;
  overflow: auto;
  background: #FFF8E2;
  /* border-radius: 50px 50px 0 0; */
  padding-top: 20px;
}

.full_chatbot .all_chatpart_design .all_chatpart_body .box {
  width: -moz-fit-content;
  width: fit-content;
  max-width: calc(100% - 30px);
  min-width: calc(20px);
  background: #090B0C;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  margin-bottom: 10px;
  padding: 7px;
  font-size: 13px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: #fff;
  border: 1.5px solid #090B0C;
}

.full_chatbot .all_chatpart_design .all_chatpart_body .box .MuiFormControl-root,
.full_chatbot .all_chatpart_design .all_chatpart_body .box .MuiFormControl_root {
  width: 100%;
  margin-bottom: 10px !important;
}

.full_chatbot .all_chatpart_design .all_chatpart_body .box .MuiFormControl-root label.MuiFormLabel-root,
.full_chatbot .all_chatpart_design .all_chatpart_body .box .MuiFormControl_root label.MuiFormLabel_root {
  line-height: 11px;
  font-size: 12px;
}

.full_chatbot .all_chatpart_design .all_chatpart_body .box .MuiFormControl-root input {
  height: auto;
  padding: 9px;
  width: 100%;
  background: #fff;
  font-size: 12px;
}

.full_chatbot .all_chatpart_design .all_chatpart_body .box .MuiFormControl-root label.MuiFormLabel-root.MuiFormLabel-filled,
.full_chatbot .all_chatpart_design .all_chatpart_body .box .MuiFormControl-root label.Mui-focused {
  top: 5px !important;
}

.full_chatbot .all_chatpart_design .all_chatpart_body .box .MuiFormControl-root .MuiSelect-select {
  font-size: 13px;
  padding: 9px;
}

.full_chatbot .all_chatpart_design .all_chatpart_body .box .MuiFormControl-root {
  background: #fff;
}

.full_chatbot .all_chatpart_design .all_chatpart_body .box.right_side {
  margin-left: auto;
  background: #fed838;
  color: #090B0C;
  border: 1.5px solid #fed838;
  border-radius: 10px;
  border-bottom-right-radius: 0px;
  word-wrap: break-word;
}

.close_text {
  font-size: 13px;
}

.send_msg img {
  width: 38px !important;
  height: auto;
  cursor: pointer;
}

.full_chatbot .all_chatpart_design .all_chatpart_footer {
  display: flex;
  margin-bottom: 0 !important;
  align-items: center;
  background: #ffffff;
  border: 1.5px solid #000;
  border-radius: 15px;
  padding: 5px 10px;
}

.full_chatbot .all_chatpart_design .all_chatpart_footer img {
  width: 20px;
  object-fit: contain;
  margin-right: 5px;
}

.chatbot_pill {
  background: #FFF8E2;
  padding: 10px 15px;
}

.full_chatbot .all_chatpart_design .all_chatpart_footer input[type="text"] {
  width: 100%;
  border: none;
  outline: none;
  padding: 2px;
  font-size: 15px;
  background-color: #fff;
  border-radius: 30px;
  color: #575859;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.full_chatbot .all_chatpart_design .all_chatpart_footer input[type="text"]::placeholder {
  color: #84878C !important;
}

.full_chatbot .all_chatpart_design .all_chatpart_footer div {
  color: #fff;
  font-weight: bold;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 0px !important;
}

.full_chatbot .all_chatpart_design .all_chatpart_body .box .checkbox_chat {
  display: flex;
  align-items: start;
  gap: 10px;
  margin-bottom: 10px;
}

.full_chatbot .all_chatpart_design .all_chatpart_body .box button.btn {
  font-size: 13px;
  background: blue;
  border: 0;
  color: #fff;
  padding: 8px 15px;
  margin-left: auto;
  margin-bottom: 4px;
  display: block;
  border-radius: 4px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}

.full_chatbot .all_chatpart_design .all_chatpart_body .box button.btn:hover,
.full_chatbot .all_chatpart_design .all_chatpart_body .box button.btn:active,
.full_chatbot .all_chatpart_design .all_chatpart_body .box button.btn:focus {
  background: #5d5dff;
}

.more_Option {
  display: flex;
  align-items: center;
  gap: 5px;
}

.online_pos {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -15px;
}

.we_online_box {
  width: auto;
  height: 28px;
  background: #ffffff;
  margin-top: -25px !important;
  display: inline-flex;
  align-items: center;
  font-family: "Inter", sans-serif;
  color: #343333;
  font-weight: 600;
  font-size: 13px;
  padding: 0 15px;
  border-radius: 30px;
  z-index: 99;
  box-shadow: 1px 1px #ddd;
}

.we_online_box img {
  width: 9px;
  object-fit: contain;
  margin-right: 4px;
}

.powered_box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 32px;
  background: #fff1c5;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 12px;
  padding: 0 15px;
  color: #886e00;
}

.powered_box img {
  width: 25px;
  object-fit: contain;
  margin: 4px;
}

.powered_box span {
  font-weight: 600;
  color: #450513;
}

.send_icon {
  height: fit-content;
  width: 20px;
  object-fit: contain;
  margin-left: 2px;
  margin-top: 2px;
  cursor: pointer;
}

.sender_box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  margin-bottom: 5px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-style: italic;
  color: #315d80;
}

.sender_box img {
  width: 15px;
  height: auto;
  object-fit: cover;
  margin-right: 5px;
  border-radius: unset;
}

.sender_right_side {
  justify-content: flex-end;
  margin-right: 5px;
}

.sender_box span {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 0.5px solid #0000001a;
  color: #7c7e82;
  font-size: 10px;
  margin-left: 4px;
  display: flex;
  align-items: center;
  line-height: 0;
  justify-content: center;
  background: rgba(41, 41, 41, 0.1);
}

.calanedar_container {
  max-width: 95%;
  width: 95%;
  /* max-height: 250px; */
  box-shadow: 0px 0px 12.100000381469727px 0px #0000001c;
  margin: 10px;
  margin-bottom: 20px;
}

.slectedChip>div {
  background: #FED838;
  color: #090B0C;
  border: 1.5px solid #FED838;
  border-radius: 15px;
}

.sender_chip>div {
  background-color: #ffffff;
  border: 1.5px solid #090B0C;
  border-radius: 15px;
  color: #090B0C;
}

.sender_chip>div:hover {
  background: #fed838 !important;
}

.place_card2 {
  position: absolute;
  z-index: 10000;
  margin-top: 10px;
  border: 1px solid #ddd;
  background-color: white;
  border-radius: 5px;
  width: 91.5%;
  bottom: 75px;
  box-shadow: rgba(0, 18, 46, 0.16) 0px 8px 36px 0px;
}

.placeList {
  padding: 0.25rem 0.25rem;
  display: flex;
  border-bottom: 1px solid #ddd;
  color: #343333 !important;
}

.placeList_active {
  padding: 0.25rem 0.25rem;
  display: flex;
  border-bottom: 1px solid #ddd;
  background: #B82142;
  color: white !important;
}

.placeList svg {
  font-size: 20px;
  color: #ddd;
  margin-top: 2px;
  margin-right: 2px;
}

.placeList:hover svg {
  color: #fff;
}

.placeList {
  width: 100%;
  font-size: 12px;
  margin: 7px;
  color: #343333;
  padding: 10px !important;
  border-radius: 0px;
}

.placeList:hover {
  background: #fed838;
  color: #1c1b1f !important;
}

.placeList_active {
  width: 100%;
  font-size: 12px;
  margin: 7px;
  color: white;
  background-color: #933eff;
}

.address_input>div>fieldset {
  border: none;
}

.address_input>div {
  padding: 0px !important;
  border: none;
}

.address_input>div>input {
  color: #464749;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.address_input>div>div {
  display: none;
  padding: 0px;
}

.chat_bubble {
  background-color: #fafafa;
  padding: 10px 28px;
  -webkit-border-radius: 20px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius: 20px;
  -moz-border-radius-bottomleft: 2px;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  display: inline-block;
}

.typing {
  align-items: center;
  display: flex;
  height: 17px;
}

.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #f8efd5;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}

.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}

.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}

.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}

.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #f8efd5;
  }

  28% {
    transform: translateY(-7px);
    background-color: #f8efd5;
  }

  44% {
    transform: translateY(0px);
    background-color: #f8efd5;
  }
}

/* ankush css */

.chatfloat_icon {
  display: flex;
  align-items: center;
  gap: 10px;
  background: #B82142;
  border-radius: 50px;
  padding: 5px 15px;
  cursor: pointer;
  position: relative;
}

.agent_icon img {
  width: 36px;
  height: auto;
}

.agent_chatline {
  font-size: 18px;
  font-family: 'Inter';
  color: #fff;
  font-weight: 600;
  text-decoration: underline;
}

.agent_icon_collapsed img {
  width: 40px;
  height: auto;
  border: 2px solid #B82142;
  border-radius: 50%;
  cursor: pointer;

}

.arrow_wrapper {
  position: absolute;
  bottom: 30px;
  right: -10px;
  animation: 2.5s infinite mover;
}

.chat_shape {
  position: relative;
  margin-bottom: -50px;

}

.chat_initiate img {
  width: 40px;
}

.chat_initiate {
  text-align: right;
  cursor: pointer;
}

.greering_message {
  background: linear-gradient(180deg, #EDEDEC 0%, #FFFFFF 51.17%, #A2A4AF 100%);
  padding: 12px 20px;
  border-radius: 23px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  color: #3E3A3A;
  font-size: 14px;
}

.notification_wrap {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}

.greeting_action {
  background: linear-gradient(180deg, #EDEDEC 0%, #FFFFFF 51.17%, #A2A4AF 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 38px;
  border-radius: 50%;
}

@keyframes mover {

  0%,
  100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-5px);
  }
}


@media screen and (max-width:1300px){
  .full_chatbot .all_chatpart_design {
    max-width: 300px;
  }
  .full_chatbot .all_chatpart_design .all_chatpart_body {
    height: calc(85vh - 260px);
  }
}


@media screen and (max-width:577px) {
  .full_chatbot .all_chatpart_design {
    right: 5%;
    width: 100%;
    max-width: 90%;
  }

  .full_chatbot {
    bottom: 0px;
    right: 2.5%;
    width: 95%;
  }

  .chatbot_outer span {
    font-weight: 600;
    color: #242424;
    font-family: 'Inter';
    font-size: 17px;
  }
}

.conversation_close{
  color: #717070  ;
  font-size: 13px;
  text-align: center;
  font-weight: 500;
  font-family: 'Inter';
}