.MuiAutocomplete-option {
  width: 100% !important;
  padding: 8px 8px !important;
}

.MuiAutocomplete-listbox {
  padding: 0 !important;
}


.jinniBotBody {
  margin: 0;
  padding: 0;
  line-height: normal;

}

.jinniBotBody * {
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}