.container {
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    background: #fff;
  }
  
  .title {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 12px;
    margin-top: 12px;
    padding-left: 12px;
  }
  
  .list {
    max-height: 230px;
    overflow-y: auto;
  }
  
  .item {
    padding: 12px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    color: #484848;
  }
  
  .item:hover {
    background-color: #f0f0f0;
  }
  
  .selected {
    background: linear-gradient(270.16deg, #bc8bfb 0.13%, #933eff 98.35%);
    color: #fff;
    font-weight: bold;
  }
  
  /* caridon */
  

  .container.container_propertyhubltd, .container.container_lundiniom, .container.container_magicbrick {
    border: 1px solid  #000;
    background: #ffc800;
    }
    .container.container_propertyhubltd  .item, .container.container_lundiniom  .item, .container.container_magicbrick  .item {
        border-bottom: 1px solid #a6a20b;
    }
    .container.container_propertyhubltd .item:hover, .container.container_lundiniom .item:hover, .container.container_magicbrick .item:hover {
        background-color: #efdb93;
    }
  /* .container.input_caridon .selected {
    background: #022b63;
    }
    .container.input_btc .selected {
     background: #3c6e95;
    }

     */