@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
 
:focus-visible {
  outline: none !important;
}
 
 
.full_chatbot {
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 55px;
}
 
/* online chat */
.online_span {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #0ed145;
  top: -2px;
  left: 0px;
}
 
.showicon {
  position: relative;
}
 
.online_wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 10px;
}
 
.live_icon {
  background: #7FD96B;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  margin-right: 8px;
}
 
.greering_message {
  background: #ffffff;
  padding: 5px 10px;
  border-radius: 6px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  color: #3E3A3A;
  font-size: 14px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-top: 10px;
  display: flex;
  gap: 6px;
  align-items: center;
  border: 0.5px solid #bbbbbb;
  position: relative;
}
 
.greering_message img {
  width: 20px;
  animation-name: wave-animation;
  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;
  /* Change to speed up or slow down */
  animation-iteration-count: infinite;
  /* Never stop waving :) */
  transform-origin: 70% 70%;
  /* Pivot around the bottom-left palm */
}
 
.greering_message span {
  line-height: 21px;
}
 
.icon_wrap {
  display: flex;
  justify-content: center;
}
 
.online_wrapper:hover .online_cancel {
  visibility: visible;
}
 
@keyframes wave-animation {
  0% {
    transform: rotate(0.0deg)
  }
 
  10% {
    transform: rotate(14.0deg)
  }
 
  /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8.0deg)
  }
 
  30% {
    transform: rotate(14.0deg)
  }
 
  40% {
    transform: rotate(-4.0deg)
  }
 
  50% {
    transform: rotate(10.0deg)
  }
 
  60% {
    transform: rotate(0.0deg)
  }
 
  /* Reset for the last half to pause */
  100% {
    transform: rotate(0.0deg)
  }
}
 
.online_cancel svg {
  font-size: 16px;
  color: #525151;
}
 
.notification_wrap {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}
 
.greeting_action {
  background: linear-gradient(180deg, #EDEDEC 0%, #FFFFFF 51.17%, #A2A4AF 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 38px;
  border-radius: 50%;
}
 
.online_dot {
  width: 8px !important;
  height: 8px !important;
  background: #0ed145;
  display: block;
  border-radius: 10px;
}
 
@keyframes color-change-green {
  0% {
    background: rgba(0, 0, 0, 0.25);
  }
 
  50% {
    background-color: #0ed145;
  }
 
  100% {
    background: rgba(0, 0, 0, 0.25);
  }
}
 
/* width */
.full_chatbot ::-webkit-scrollbar {
  width: 8px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}
 
/* Track */
.full_chatbot ::-webkit-scrollbar-track {
  background: #f1f1f1;
  overflow: hidden;
}
 
/* Handle */
.full_chatbot ::-webkit-scrollbar-thumb {
  background: #545659;
}
 
/* Handle on hover */
.full_chatbot ::-webkit-scrollbar-thumb:hover {
  background: #454545;
}


 
.full_chatbot .iconset .crossico {
  font-size: 32px;
  background: #ffc800;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  z-index: 15;
  position: relative;
  cursor: pointer;
}
 
.full_chatbot .iconset .showicon {
 
  height: auto;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  z-index: 15;
  position: relative;
  cursor: pointer;
}
 
.full_chatbot .iconset .showicon img {
  width: 55px;
}
 
.full_chatbot .all_chatpart_design {
  position: fixed;
  bottom: 75px;
  right: 10px;
  background: #000;
  width: 100%;
  max-width: 300px;
  box-shadow: rgba(0, 18, 46, 0.16) 0px 8px 36px 0px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}
 
.full_chatbot .all_chatpart_design .all_chatpart_header .with_avtar img {
  width: 40px;
  height: 40px;
  object-fit: cover;
 
  padding: 1px;
  border-radius: 50%;
}
 
.full_chatbot .all_chatpart_design .all_chatpart_header {
  background: #323338;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  font-family: "Inter", sans-serif;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  display: flex;
  gap: 20px;
  padding: 8px 14px;
  justify-content: space-between;
  align-items: center;
}
 
.full_chatbot .all_chatpart_design .all_chatpart_header .with_avtar {
  display: flex;
  align-items: center;
  gap: 10px;
}
 
.full_chatbot .all_chatpart_design .all_chatpart_header .donw_arrow {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
 
.full_chatbot .all_chatpart_design .all_chatpart_body {
  padding: 0 15px;
  height: calc(30vh);
  max-height: 420px;
  overflow: auto;
}
 
.full_chatbot .all_chatpart_design .all_chatpart_body .box  {
  width: -moz-fit-content;
  width: fit-content;
  max-width: calc(100% - 30px);
  min-width: calc(20px);
  background: #ffc800;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  margin-bottom: 10px;
  padding: 10px;
  line-height: 1.4;
  font-size: 12px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: #0c0c0c;
  width: fit-content;
  margin-left: unset;
  margin-right: unset;
}
.full_chatbot .all_chatpart_design .all_chatpart_body .box div {
  font-size: 12px !important;
  width: fit-content;
  margin-left: unset;
  margin-right: unset;
  text-align: left;
}
 
.full_chatbot .all_chatpart_design .all_chatpart_body .box .MuiFormControl-root,
.full_chatbot .all_chatpart_design .all_chatpart_body .box .MuiFormControl_root {
  width: 100%;
  margin-bottom: 10px !important;
}
 
.full_chatbot .all_chatpart_design .all_chatpart_body .box .MuiFormControl-root label.MuiFormLabel-root,
.full_chatbot .all_chatpart_design .all_chatpart_body .box .MuiFormControl_root label.MuiFormLabel_root {
  line-height: 11px;
  font-size: 12px;
}
 
.full_chatbot .all_chatpart_design .all_chatpart_body .box .MuiFormControl-root input {
  height: auto;
  padding: 9px;
  width: 100%;
  background: #fff;
  font-size: 12px;
}
 
.full_chatbot .all_chatpart_design .all_chatpart_body .box .MuiFormControl-root label.MuiFormLabel-root.MuiFormLabel-filled,
.full_chatbot .all_chatpart_design .all_chatpart_body .box .MuiFormControl-root label.Mui-focused {
  top: 5px !important;
}
 
.full_chatbot .all_chatpart_design .all_chatpart_body .box .MuiFormControl-root .MuiSelect-select {
  font-size: 13px;
  padding: 9px;
}
 
.full_chatbot .all_chatpart_design .all_chatpart_body .box .MuiFormControl-root {
  background: #fff;
}
 
.full_chatbot .all_chatpart_design .all_chatpart_body .box.right_side {
  margin-left: auto;
  background: #545659;
  color: #ffffff;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 20px;
  width: fit-content;
   
    margin-right: unset !important;
}
.full_chatbot .all_chatpart_design .all_chatpart_body .box.right_side div {
  width: fit-content;
  margin-left: unset !important;
  margin-right: unset !important;
}
 
.full_chatbot .all_chatpart_design .all_chatpart_footer {
  padding: 10px 15px;
  margin-top: 5px;
  display: flex;
  margin-bottom: 0;
  align-items: center;
  border-top: 1px solid #ffeded75;
}
 
.full_chatbot .all_chatpart_design .all_chatpart_footer img {
  width: 20px;
  object-fit: contain;
  margin-right: 5px;
}
 
.full_chatbot .all_chatpart_design .all_chatpart_footer input[type="text"] {
  width: 100%;
  border: none;
  outline: none;
  padding: 2px;
  font-size: 14px;
  background: #000;
  color: #fff;
}
 
.full_chatbot .all_chatpart_design .all_chatpart_footer input[type="text"]::placeholder {
  color: #f1f1f1 !important;
}
 
.full_chatbot .all_chatpart_design .all_chatpart_footer div {
  color: #fff;
  font-weight: bold;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0px !important;
}
 
.full_chatbot .all_chatpart_design .all_chatpart_body .box .checkbox_chat {
  display: flex;
  align-items: start;
  gap: 10px;
  margin-bottom: 10px;
}
 
.full_chatbot .all_chatpart_design .all_chatpart_body .box button.btn {
  font-size: 13px;
  background: blue;
  border: 0;
  color: #fff;
  padding: 8px 15px;
  margin-left: auto;
  margin-bottom: 4px;
  display: block;
  border-radius: 4px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}
 
.full_chatbot .all_chatpart_design .all_chatpart_body .box button.btn:hover,
.full_chatbot .all_chatpart_design .all_chatpart_body .box button.btn:active,
.full_chatbot .all_chatpart_design .all_chatpart_body .box button.btn:focus {
  background: #5d5dff;
}
 
.more_Option {
  display: flex;
  align-items: center;
  gap: 0px;
}
 
.we_online_box {
  width: 100%;
    height: 26px;
    background: #414456;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    font-family: "Inter", sans-serif;
    color: #fffdfd;
    font-weight: 600;
    font-size: 12px;
    padding: 0 15px;
}
 
.we_online_box img {
  width: 9px;
  object-fit: contain;
  margin-right: 4px;
 
}
 
.powered_box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  background: #3C3E49;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 12px;
  padding: 0 15px;
  color: #fff;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
 
.powered_box img {
  width: 23px;
  object-fit: contain;
  margin: 4px;
}
 
.powered_box span {
  font-weight: 600;
  color: #fff;
}
 
.send_icon {
  height: fit-content;
  width: 20px;
  object-fit: contain;
  margin-left: 2px;
  margin-top: 2px;
  cursor: pointer;
}
 
.sender_chip>div {
  background-color: transparent;
  border: 1.5px solid #f6f6f7;
  border-radius: 15px;
  color: #fff;
  font-size: 12px !important;
  width: 100%;
}
 
.sender_chip>div:hover {
  background: #545659 !important;
  color: #fff;
  border-color: #545659 !important;
}
 
.sender_box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 11px;
  margin-bottom: 4px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-style: italic;
  color: #f1f1f1;
  margin-left: unset;
  margin-right: unset;
  width: fit-content;
}
 
.sender_box img {
  width: 15px;
  height: 15px;
  object-fit: cover;
  margin-right: 5px;
  border-radius: 50%;
}
 
.sender_right_side {
  justify-content: flex-end;
  margin-right: 5px;
  width: auto;
}
 
.sender_box span {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 0.5px solid #b7b2b21a;
  color: #e1e4ea;
  font-size: 10px;
  margin-left: 4px;
  display: flex;
  align-items: center;
  line-height: 0;
  justify-content: center;
  background: rgb(84 86 89);
}
 
.calanedar_container {
  max-width: 95%;
  width: 95%;
  /* max-height: 250px; */
  box-shadow: 0px 0px 12.100000381469727px 0px #0000001c;
  margin: 10px;
  margin-bottom: 20px;
}
 
.slectedChip>div {
  background: #545659;
  color: #fff;
  font-size: 12px !important;
  width: 100%;
}
 
.place_card2 {
  position: absolute;
  z-index: 10000;
  margin-top: 10px;
  border: 1px solid #ddd;
  background-color: white;
  border-radius: 5px;
  width: 91.5%;
  bottom: 75px;
  box-shadow: rgba(0, 18, 46, 0.16) 0px 8px 36px 0px;
}
 
.placeList {
  width: 100%;
  font-size: 12px;
  margin: 7px;
  color: #343333;
  padding: 10px !important;
  border-radius: 0px;
}
 
.placeList_active {
  padding: 0.25rem 0.25rem;
  display: flex;
  border-bottom: 1px solid #ddd;
  background: linear-gradient(90deg, #FED838 -50.48%, #DBBE43 100%);
  color: white !important;
}
 
.placeList svg {
  font-size: 20px;
  color: #ddd;
  margin-top: 2px;
  margin-right: 2px;
}
 
.placeList:hover {
  background: #ffc800;
  color: #0c0c0c !important;
}
 
.placeList {
  width: 100%;
  font-size: 12px;
  margin: 7px;
  color: #343333;
}
 
.placeList_active {
  width: 100%;
  font-size: 12px;
  margin: 7px;
  color: white;
  background-color: linear-gradient(90deg, #FED838 -50.48%, #DBBE43 100%);
}
 
.address_input>div>fieldset {
  border: none;
}
 
.address_input>div {
  padding: 0px !important;
  border: none;
}
 
.address_input>div>input {
  color: #464749;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}
 
.address_input>div>div {
  display: none;
  padding: 0px;
}
 
.chat_bubble {
  background: #ffcc00;
  padding: 10px 20px;
  -webkit-border-radius: 20px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius: 20px;
  -moz-border-radius-bottomleft: 2px;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  width: fit-content;
  margin-left: unset;
  margin-right: unset
}
 
.typing {
  align-items: center;
  display: flex;
  height: 10px;
  width: fit-content;
  margin-left: unset !important;
  margin-left: unset !important;
}
 
.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #0c0c0c;
  border-radius: 50%;
  height: 5px;
  margin-right: 4px;
  vertical-align: middle;
  width: 5px;
  display: inline-block;
}
 
.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}
 
.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}
 
.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}
 
.typing .dot:last-child {
  margin-right: 0;
}
 
@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #272727;
  }
 
  28% {
    transform: translateY(-7px);
    background-color: #272727;
  }
 
  44% {
    transform: translateY(0px);
    background-color: #272727;
  }
}
 
@media screen and (max-width:1300px) {
  .full_chatbot ::-webkit-scrollbar {
    width: 6px;
  }
 
  .full_chatbot .all_chatpart_design {
    max-width: 280px;
  }
  .full_chatbot .all_chatpart_design .all_chatpart_body {
    height: calc(30vh);
  }
}
 
@media screen and (max-width:820px){
  .full_chatbot .all_chatpart_design .all_chatpart_header .with_avtar img {
   
    margin-left: unset;
    margin-right: unset;
  }
  .more_Option {
    width: 20%;
  }
  .full_chatbot .all_chatpart_design .all_chatpart_header .with_avtar {
   
    width: 100%;
  }
  .full_chatbot .all_chatpart_design .all_chatpart_footer div {
 
    width: auto;
  }
}
 
@media screen and (max-width:577px) {
  .full_chatbot .all_chatpart_design {
    right: 5%;
    width: 100%;
    max-width: 90%;
  }
}


.conversation_close{
  color: #717070  ;
  font-size: 13px;
  text-align: center;
  font-weight: 500;
  font-family: 'Inter';
}